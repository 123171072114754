import type { AccountRole, WorkspaceRole } from '@/data/roles'
import type { UserGroupListItem } from '@/modules/user-management/groups/types'

export enum UserStateFilter {
  All = 'all',
  Active = 'active',
  Connected = 'connected',
  Pending = 'pending',
  ActionNeeded = 'action_needed',
  // SCIM
  ScimSetup = 'scim_setup',
  ManualSetup = 'manual_setup',
  // Zendesk
  Admin = 'admin',
  User = 'user',
  NoPermissions = 'no_permissions',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'INVITED',
  Connected = 'CONNECTED',
}

export interface WorkspaceItem {
  id: string
  name: string
  color: string
  totalMembers: number
  role?: WorkspaceRole
}

export interface User {
  id: string
  name: string
  email: string
  avatar: string | null
  owner: boolean
  accountRole: AccountRole
  pendingInviteAccountRole: AccountRole | null
  lastLoginAt: string
  status: UserStatus
  readonly: boolean
  activeSince: string
  hasMultipleAccounts: boolean
  canSetAsBot?: boolean
}

export interface AccountUser extends User {
  workspaces: WorkspaceItem[]
  userGroups: UserGroupListItem[]
}

export interface WorkspaceUser extends User {
  workspaceRole: WorkspaceRole
  pendingInviteWorkspaceRole: WorkspaceRole | null
  userGroups: UserGroupListItem[]
}

interface ExternalGroupedUser {
  id: string
  name: string
  avatar: string | null
  importedAt: string
  connectionName: string
  connectionType: string
  connectionCreated: string
}

export interface ExternalUser {
  name: string
  email: string
  avatar: string | null
  groupedUsers: ExternalGroupedUser[]
}
