import { createSharedComposable } from '@vueuse/core'
import { PusherActivityPayload_PusherEvent, type PusherActivityPayload } from '@klausapp/services/users'
import { pusherClient } from '@/utils/pusher'
import { type ActivityType } from '@/modules/activity/types'
import { bus } from '@/utils/bus'
import config from '@/config'
import { reloadActivitiesCount } from './useActivitiesCount'
import { session } from './useSession'

export default createSharedComposable(() => {
  function handleActivityEvent(data: PusherActivityPayload, activityType: ActivityType) {
    if (session.workspace.id !== Number(data.workspaceId)) return

    reloadActivitiesCount()
    bus.$emit('refresh-activities-items', activityType)
  }

  if (config.embeddedRuntime) return

  pusherClient.signin()
  pusherClient.user.bind(PusherActivityPayload_PusherEvent.DISPUTE_RECEIVED, (data) =>
    handleActivityEvent(data, 'disputes'),
  )
  pusherClient.user.bind(PusherActivityPayload_PusherEvent.REVIEW_RECEIVED, (data) =>
    handleActivityEvent(data, 'reviews'),
  )
})
