import { computed, reactive, watch } from 'vue'
import { createEventHook, createSharedComposable } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { useQuery } from '@tanstack/vue-query'
import type {
  AssignmentV2_GetAssignmentInfoResponse,
  AssignmentV2_GetAssignmentInfoResponse_UserInfo,
  AssignmentV2_UserAssignment,
} from '@klausapp/services/assignments_v2'
import { getAssignmentInfo } from '@/modules/tasks/api'
import { session } from '@/composables/useSession'
import useTasks from '@/composables/useTasks'

type GoalState = Pick<
  AssignmentV2_UserAssignment,
  'goal' | 'personalGoal' | 'reviewCount' | 'personalReviewCount' | 'cycleEnd'
>

export default createSharedComposable(() => {
  const route = useRoute()
  const { state: tasksState, currFinished } = useTasks()
  const assignmentId = computed(() => route.params.assignmentId?.toString())

  const { data: assignmentData, refetch } = useQuery({
    queryKey: computed(() => {
      return [`assignment-info-${assignmentId.value}`]
    }),
    queryFn: () => getAssignmentInfo(assignmentId.value.toString()),
    enabled: computed(() => !!assignmentId.value && !route.name.toString().startsWith('workspace.assignments')),
    refetchOnMount: 'always',
  })

  const goalState: GoalState = reactive({
    goal: 0,
    personalGoal: 0,
    reviewCount: 0,
    personalReviewCount: 0,
    cycleEnd: null,
  })

  const onUpdate = createEventHook<void>()

  onUpdate.on(refetch)

  const updateGoalState = (data: AssignmentV2_GetAssignmentInfoResponse) => {
    const { personalGoal, personalReviewCount } = getPersonalGoal(data.reviewers)

    goalState.cycleEnd = data?.cycle.end || null
    goalState.goal = data?.goal || 0
    goalState.reviewCount = data?.reviewCount || 0
    goalState.personalGoal = personalGoal
    goalState.personalReviewCount = personalReviewCount

    const currAssignment = tasksState.allAssignments?.find((a) => a.id === assignmentId.value)

    if (currAssignment && goalState.goal) {
      const oldReviewCount = currAssignment.reviewCount

      currAssignment.goal = goalState.goal
      currAssignment.reviewCount = goalState.reviewCount

      if (personalGoal) currAssignment.personalGoal = goalState.personalGoal
      if (personalReviewCount) currAssignment.personalReviewCount = goalState.personalReviewCount

      if (oldReviewCount && oldReviewCount !== goalState.reviewCount && goalState.goal === goalState.reviewCount) {
        currFinished.value = [currAssignment]
      }
    }
  }

  function getPersonalGoal(reviewers: AssignmentV2_GetAssignmentInfoResponse_UserInfo[]) {
    const reviewer = reviewers?.find((r) => r.user.id === session.user.id?.toString())

    return {
      personalGoal: reviewer?.goal || 0,
      personalReviewCount: reviewer?.reviewCount || 0,
    }
  }

  watch(
    assignmentData,
    (newData) => {
      if (newData) {
        updateGoalState(newData)
      }
    },
    { immediate: true },
  )

  return { goalState, updateGoalCounts: onUpdate.trigger }
})
