import { reactive, computed, watch } from 'vue'

import i18n from '@/i18n'
import analytics from '@/utils/analytics'
import { bus } from '@/utils/bus'
import { sidebarState } from '@/composables/useReviewSidebar'
import { session } from '@/composables/useSession'
import { getWorkspaceUsers } from '@/modules/user-management/api'
import { type WorkspaceUser, UserStatus } from '@/modules/user-management/types'
import { isWorkspaceRoleIncluded } from '@/utils/roleUtils'
import { layoutState } from '@/composables/usePageLayout'

import type { Feedback } from '../types/feedback'
import type { Dispute } from '../types/dispute'
import { getDisputes, type DisputePayload, disputeReview, disputeReviewee, removeDispute } from '../api/disputes'

export interface DisputeDetails extends Omit<Dispute, 'id'> {
  id?: string
}

export type DisputeAction = 'APPROVE' | 'REJECT' | 'EDIT'

export interface State {
  dispute?: DisputeDetails
  disputing: boolean
  disputesList: Dispute[]
  users: WorkspaceUser[]
  assignees: WorkspaceUser[]
  reviewId?: string
  action?: DisputeAction
}

const defaultState: State = {
  dispute: undefined,
  disputing: false,
  disputesList: [],
  users: [],
  assignees: [],
  reviewId: undefined,
  action: undefined,
}

export const disputeState = reactive(defaultState)

loadDisputeAssignees()

export const translationKey = computed(() => {
  const dispute = disputeState.dispute
  const status = disputeState.action || dispute?.status
  return `${dispute?.type}-${status}`
})

export const disputeTitle = computed(() => {
  const key = translationKey.value
  const translationMap = {
    'RATINGS-NEW': 'conversations.sidebar.disputes.titles.dispute_review',
    'RATINGS-APPROVE': 'conversations.sidebar.disputes.titles.approve_review',
    'RATINGS-REJECT': 'conversations.sidebar.disputes.titles.reject_review',
    'RATINGS-EDIT': 'conversations.sidebar.disputes.titles.edit_review',
    'REVIEWEE-NEW': 'conversations.sidebar.disputes.titles.dispute_reviewee',
    'REVIEWEE-EDIT': 'conversations.sidebar.disputes.titles.dispute_reviewee',
    'REVIEWEE-APPROVE': 'conversations.sidebar.disputes.titles.approve_reviewee',
    'REVIEWEE-REJECT': 'conversations.sidebar.disputes.titles.reject_reviewee',
  }

  return translationMap[key] ? i18n.t(translationMap[key], [disputeState.dispute?.assignee.name]) : ''
})

watch(
  () => disputeState.disputesList,
  (list) => {
    if (!list.length) return
    selectDisputeById(sidebarState.disputeId)
  },
  { immediate: true },
)

export function getDisputeStatus(dispute: Dispute) {
  const i18nMap = {
    NEW: 'conversations.sidebar.disputes.status.new',
    ACCEPTED: 'conversations.sidebar.disputes.status.approved',
    PARTIALLY_ACCEPTED: 'conversations.sidebar.disputes.status.partially_accepted',
    REJECTED: 'conversations.sidebar.disputes.status.rejected',
  }
  return i18n.t(i18nMap[dispute.status])
}

const getBlankDispute = (review: Feedback): DisputeDetails => {
  const reviewerId = parseInt(review.reviewerId, 10)
  const reviewer = sidebarState.workspaceUsers.find((u) => u.id === reviewerId) || {
    id: reviewerId,
    name: review.reviewerName,
  }
  const assignee = {
    id: (reviewer.id || '').toString(),
    name: reviewer.name,
    avatar: '',
  }
  const categories = review.ratings.map((r) => ({
    id: r.categoryId,
    expectedRating: r.rating,
    note: '',
    rootCause: r.cause,
  }))

  return {
    comment: {
      Id: '0',
      comment: '',
      commentTags: [],
      seen: false,
    },
    categories,
    status: 'NEW',
    type: 'RATINGS',
    reviewId: review.id,
    originalReview: review,
    steps: [],
    assignee,
    createdAt: '',
    createdBy: assignee,
    viewed: null,
  }
}

export async function loadDisputes(connectionId?: number, conversationId?: string) {
  if (!connectionId) connectionId = sidebarState.connectionId
  if (!conversationId) conversationId = sidebarState.conversationId

  const { disputes } = await getDisputes({ connectionId, conversationId })
  disputeState.disputesList = disputes
  loadDisputeAssignees()
}

export async function loadDisputeAssignees() {
  if (!session?.workspace?.id) return

  const { users } = await getWorkspaceUsers(session.workspace.id)
  const activeUserId = session.user.id.toString()
  disputeState.users = users
    .slice()
    .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

  const assignees = users.filter(
    (u) =>
      u.id !== activeUserId &&
      u.status === UserStatus.Active &&
      !(u.workspaceRole && !isWorkspaceRoleIncluded(u.workspaceRole, ['MANAGER', 'LEAD'])),
  )

  // If original reviewer is not in the list of assignees, add them
  const review = disputeState.dispute?.originalReview
  const reviewer = review?.reviewerId
  if (reviewer && reviewer !== activeUserId && !assignees.find((u) => u.id === reviewer)) {
    const user = users.find((u) => u.id === reviewer)
    user && assignees.push(user)
  }

  disputeState.assignees = assignees.sort((a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
  )
}

export function startDisputingReview(review: Feedback) {
  loadDisputeAssignees()
  disputeState.dispute = getBlankDispute(review)
  disputeState.disputing = true
  disputeState.dispute.type = 'RATINGS'
  disputeState.reviewId = review.id
  analytics.disputeStartReview()

  if (!layoutState.value.rightSidebar) bus.$emit('toggle-review-sidebar')
}

export function startDisputingReviewee(review: Feedback) {
  loadDisputeAssignees()
  disputeState.dispute = getBlankDispute(review)
  disputeState.disputing = true
  disputeState.dispute.type = 'REVIEWEE'
  disputeState.reviewId = review.id
  analytics.disputeStartReviewee()

  if (!layoutState.value.rightSidebar) bus.$emit('toggle-review-sidebar')
}

export function resetDispute() {
  if (!disputeState.action) {
    disputeState.dispute = undefined
  }

  discardDispute()
}

export function discardDispute() {
  disputeState.disputing = false
  disputeState.action = undefined
}

export async function createNewDispute(payload: DisputePayload) {
  const method = !payload.data.categories ? disputeReviewee : disputeReview
  const disputes = await method(payload)

  resetDispute()
  disputeState.disputesList = disputes.disputes
  return disputes
}

export async function deleteDispute(disputeId?: string) {
  const dispute = disputeId || disputeState.dispute?.id
  if (!dispute || !sidebarState.connectionId || !sidebarState.conversationId) return

  const { disputes } = await removeDispute({
    disputeId: dispute,
    connectionId: sidebarState.connectionId,
    conversationId: sidebarState.conversationId,
  })
  disputeState.disputesList = disputes
  resetDispute()
  analytics.disputeDelete()
}

export function getReviewDisputes(reviewId: string) {
  return disputeState.disputesList.filter((dispute) => dispute.reviewId === reviewId)
}

export function selectDisputeById(disputeId: string | undefined) {
  if (!disputeId) return
  disputeState.dispute = disputeState.disputesList.find(({ id }) => id === disputeId)
}
