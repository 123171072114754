import type { StateChanger } from 'v3-infinite-loading'
import {
  type AssignmentV2_UserAssignment,
  type AssignmentV2_GetUserAssignmentConversationsResponse_TicketListItem as AssignmentTaskItem,
  type AssignmentV2_GetUserAssignmentConversationsRequest,
  AssignmentV2_GetUserAssignmentConversationsResponse_ConversationStatus as ConversationStatus,
} from '@klausapp/services/assignments_v2'
import type { TicketListItemExt } from '@/modules/conversations/types'
import { SortValues } from '@/modules/tasks/utils/assignmentOptions'
import { type CalibrationSession } from '@/api/calibration'
export interface List {
  isLoading: boolean
  isFilterLoading: boolean
  items: AssignmentTaskItem[] | TicketListItemExt[]
  total?: string
  type: 'assignment' | 'calibration'
}

export interface TasksListParams extends Partial<Omit<AssignmentV2_GetUserAssignmentConversationsRequest, 'limit'>> {
  page: number
  limit: number
  revieweeId?: number
  botRevieweeId?: string
  reviewerId?: number
  reviewed?: boolean
  sort: SortValues
  status: ConversationStatus | 'ALL'
}

export interface State {
  activeAssignment?: Omit<
    AssignmentV2_UserAssignment,
    'goal' | 'personalGoal' | 'reviewCount' | 'personalReviewCount' | 'cycleEnd'
  >
  allAssignments: AssignmentV2_UserAssignment[] | undefined
  activeCalibrationSession?: CalibrationSession
  allCalibrationSessions: CalibrationSession[] | undefined
  infiniteLoader: StateChanger | null
  list: List
  listParams: TasksListParams
  changedListItem?: AssignmentTaskItem[]
  replaceReason?: string
}

export const defaultState: State = {
  activeAssignment: {
    id: '',
    name: '',
    settings: undefined,
    isReviewer: false,
  },
  allAssignments: [],
  activeCalibrationSession: {
    sessionId: null,
    title: '',
    dueAt: '',
    createdBy: null,
    createdAt: '',
    updatedBy: null,
    updatedAt: '',
  },
  allCalibrationSessions: [],
  list: {
    isLoading: true,
    isFilterLoading: false,
    items: [],
    total: '0',
    type: 'assignment',
  },
  infiniteLoader: null,
  changedListItem: [],
  listParams: {
    page: 0,
    limit: 100,
    revieweeId: 0,
    reviewerId: 0,
    reviewed: false,
    seed: '',
    sort: SortValues.RANDOM,
    status: ConversationStatus.TODO,
  },
  replaceReason: null,
}
