import { computed, reactive } from 'vue'

import { session } from '@/composables/useSession'

import { getActivitiesCount } from '@/modules/activity/api'
import type { ActivitiesCount } from '@/modules/activity/types'

const activitiesCount = reactive<{ state: ActivitiesCount; isLoaded: boolean }>({
  isLoaded: false,
  state: {
    total: 0,
    comments: {
      received: 0,
    },
    reviews: {
      received: 0,
    },
    feedback: {
      received: 0,
    },
    disputes: {
      all: 0,
      received: 0,
      started: 0,
    },
  },
})

export const activitiesCountState = computed(() => activitiesCount.state)

export const reloadActivitiesCount = () => {
  if (!session.workspace) return
  getActivitiesCount()
    .then((counts) => {
      activitiesCount.state.total = Number(counts.total) || 0
      activitiesCount.state.comments.received = Number(counts.comments?.received) || 0
      activitiesCount.state.reviews.received = Number(counts.reviews?.received) || 0
      activitiesCount.state.feedback.received = Number(counts.feedback?.received) || 0
      activitiesCount.state.disputes = {
        all: Number(counts.disputes?.all) || 0,
        received: Number(counts.disputes?.received) || 0,
        started: Number(counts.disputes?.started) || 0,
      }
    })
    .catch((err) => console.info(err))
}

export const useActivitiesCount = () => {
  if (!activitiesCount.isLoaded) {
    reloadActivitiesCount()

    activitiesCount.isLoaded = true
  }

  return activitiesCountState
}
