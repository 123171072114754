import { session } from '@/composables/useSession'
import config from '@/config'

export const getDashboardPreviewLink = (referenceValue: string | undefined) => {
  const query = new URLSearchParams(referenceValue || '')
  query.delete('originalTimePeriod')
  const categoryId = query.get('categoryId')
  const categoryPath = categoryId ? `/category/${categoryId}` : ''
  return `${config.baseUrl}/dashboard${categoryPath}?` + query.toString()
}

export const getCsatDashboardPreviewLink = (referenceValue: string | undefined) => {
  const query = new URLSearchParams(referenceValue || '')
  query.delete('originalTimePeriod')
  return `${config.baseUrl}/dashboard/surveys?` + query.toString()
}

export const getDisputesDashboardPreviewLink = (referenceValue: string | undefined) => {
  const query = new URLSearchParams(referenceValue || '')
  query.delete('originalTimePeriod')
  return `${config.baseUrl}/dashboard/disputes?` + query.toString()
}

export const getConversationPreviewLink = (referenceValue: string | undefined) => {
  const params = new URLSearchParams(referenceValue || '')
  const query = new URLSearchParams()
  query.append('account', session.account.id.toString())
  query.append('workspace', params.get('workspaceId') || '')

  const conversationId = params.get('conversationExternalId')
  const connectionId = params.get('connectionId')
  return `${config.baseUrl}/conversations/${connectionId}/${conversationId}?` + query.toString()
}
