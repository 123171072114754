import { themes } from '@klausapp/ui-kit'
import { session } from '@/composables/useSession'
import config, { hasZendeskProxy } from '@/config'

export function getTheme() {
  const { klaus, zendesk } = themes

  const override = sessionStorage.getItem('zendeskTheme') === 'true'
  return session.features.zendeskRebrandDev || config.rebrandDev || hasZendeskProxy || override ? zendesk : klaus
}

export function isZendeskTheme() {
  return getTheme().name === 'zendesk'
}

export default function useTheme() {
  return { theme: getTheme() }
}
