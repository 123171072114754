import i18n, { FALLBACK_LOCALE } from '@/i18n'

// returns an array of translation strings for a given key
export const getTranslations = (key: string) => {
  if (!key) return []

  const translationsObject = i18n.tm(key)
  return Object.keys(translationsObject).map((k: string) => i18n.rt(translationsObject[k]))
}

// check if translation exists for a given key both in global OR fallback locale messages
export const translationExists = (key: string) => {
  return i18n.te(key) || i18n.te(key, FALLBACK_LOCALE)
}
